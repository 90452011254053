<template>
    <b-card>
        <b-row class="mb-2">
            <b-col class="text-right">
                <b-button variant="primary" :to="{ name: 'ServiceNew' }">
                    <font-awesome-icon icon="plus"></font-awesome-icon> Nuevo
                </b-button>
            </b-col>
        </b-row>
        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Mostrar" append="registros">
                    <b-form-select v-model="services.itemsPerPage" id="perPageSelect" size="sm" :options="services.pageOptions"
                                   style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>

            <!--<b-col sm="7" md="6" class="my-1">
                <b-input-group prepend="Filtrar: ">
                    <b-input v-model="services.filter" debounce="300"></b-input>
                </b-input-group>
            </b-col>-->
        </b-row>
        <b-table :items="loadData" :fields="services.fields" :filter="services.filter" :current-page="services.currentPage"
                 :per-page="services.itemsPerPage" empty-text="Sin registros" empty-filtered-text="Sin coincidencias"
                 striped bordered hover small show-empty :responsive="true" :sort-by="services.sortBy" :sort-desc="services.sortDesc"
                 ref="servicesTable" class="text-left mb-0" @context-changed="tableContextChanged">
            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in services.fields" :key="field.key"
                          :class="services.filters[index] && services.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="services.filters[index]">
                            <b-col v-if="services.filters[index].inputType === 'Select'">
                                <b-select v-model="services.filters[index].value">
                                    <template v-if="'selectOptions' in services.filters[index]">
                                        <b-select-option v-for="option in services.filters[index].selectOptions" :value="option.value" :key="option.value">
                                            {{ option.text }}
                                        </b-select-option>
                                    </template>
                                    <template v-else>
                                        <b-select-option value="">Todos</b-select-option>
                                        <b-select-option value="TRUE">Activo</b-select-option>
                                        <b-select-option value="FALSE">Inactivo</b-select-option>
                                    </template>
                                </b-select>
                            </b-col>
                            <b-col v-else-if="services.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="services.filters[index].value" debounce="300"></b-input>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>

            <template #cell(fares)="row">
                <template v-if="row.item.childrenFare !== null">Niños: <span class="float-right">$ {{ formatCurrency(row.item.childrenFare) }}</span><br></template>
                <template v-if="row.item.adultFare !== null">Adultos:  <span class="float-right">$ {{ formatCurrency(row.item.adultFare) }}</span><br></template>
                <template v-if="row.item.serviceFare !== null">Exclusivo:  <span class="float-right">$ {{ formatCurrency(row.item.serviceFare) }}</span></template>
            </template>

            <template #cell(enabled)="row">
                <b-badge variant="primary" v-if="row.item.enabled" class="p-1">Activo</b-badge>
                <b-badge variant="warning" v-else>Inactivo</b-badge>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" :to="{ name: 'ServiceShow', params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Detalle" variant="outline-primary">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" :to="{ name: 'ServiceEdit', params: { id: row.item.id }}" v-b-tooltip.hover
                              title="Modificar" variant="outline-primary">
                        <font-awesome-icon icon="edit"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" v-b-tooltip.hover :title="row.item.enabled ? 'Desactivar' : 'Activar'"
                              variant="outline-primary" @click="changeStatus(row.item)">
                        <font-awesome-icon :icon="row.item.enabled ? 'toggle-off' : 'toggle-on'"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Mostrando {{ start }} a {{ end }} de {{ services.totalRows }} registro<template v-if="services.totalRows !== 1">s</template>
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="services.currentPage" :total-rows="services.totalRows"
                                      :per-page="services.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>

<script>
import { serviceListUrl, serviceEnableUrl } from '@routes';
import * as constants from '@constants';
import { formatCurrency, formatTime } from '../../js/utilities';
import tableStateMixin from '../../mixins/tableState';

export default {
    mixins: [tableStateMixin],
    data() {
        return {
            services: {
                fields: [
                    // { key: 'id', label: 'id', sortable: true },
                    { key: 'name', label: 'Nombre', sortable: true },
                    { key: 'entityType', label: 'Categoría', sortable: true },
                    { key: 'duration', label: 'Duración', sortable: true },
                    { key: 'durationType', label: 'Horario', sortable: true },
                    { key: 'serviceType', label: 'Tipo de servicio', sortable: true },
                    { key: 'fares', label: 'Tarifas', sortable: false },
                    { key: 'enabled', label: 'Estatus', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Acciones', class: 'text-center' }
                ],
                filters: [
                    { column: 's.name', type: 'STRING', value: '', format: '' },
                    { column: 's.entity_type', type: 'STRING', value: '', format: '', inputType: 'Select',
                        selectOptions: [{ value: '', text: 'Todos' }, { value: 'TOUR', text: 'Tour' }, { value: 'TRANSFER', text: 'Traslado' }]
                    },
                    { column: "CONCAT(s.duration, ' día(s)')", type: 'STRING', value: '', format: '' },
                    { column: 's.duration_type', type: 'STRING', value: '', format: '', inputType: 'Select',
                        selectOptions: [{ value: '', text: 'Todos' }, { value: 'ALL', text: 'Todo el dia' }, { value: 'SPECIFIC', text: 'Horario especifico' }]
                    },
                    { column: 'serviceType', type: 'STRING', value: '', format: '', inputType: 'Select',
                        selectOptions: [{ value: '', text: 'Todos' }, { value: 'COLLECTIVE', text: 'Colectivo' }, { value: 'EXCLUSIVE', text: 'Exclusivo' }]
                    },
                    { column: '', type: '', value: '', format: '' },
                    { column: 's.enabled', type: 'BOOLEAN', value: '', format: '', inputType: 'Select' }
                ],
                filter: '',
                isLoading: false,
                pageOptions: [25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 's.name',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Servicios',
                path: []
            }
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.services, this.tableState);
    },
    methods: {
        loadData(ctx) {
            if (ctx.sortBy !== '') {
                let data = {
                    filters: this.services.filters.filter(filter => filter.value.trim() !== ''),
                    ...ctx
                };

                data.currentPage = (data.currentPage - 1) * data.perPage;

                return this.axios.post(serviceListUrl(), data).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.services.totalRows = response.data.totalRows;
                        return response.data.services;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    console.error(error);

                    return [];
                });
            }
        },
        formatTime: formatTime,
        formatCurrency: formatCurrency,
        changeStatus(service) {
            this.$swal({
                title: `¿${service.enabled ? 'Desactivar' : 'Activar'} el servicio ${service.name}?`,
                icon: 'question'
            }).then(result => {
                if (result.value) {
                    this.axios.put(serviceEnableUrl(), {
                        serviceId: service.id,
                        enable: !service.enabled
                    }).then(response => {
                        if (response.data.code === constants.CODE_OK) {
                            service.enabled = !service.enabled;

                            this.$bvToast.toast(response.data.message, {
                                title: 'Services',
                                variant: 'success'
                            });

                            this.$refs.servicesTable.refresh();
                            this.$emit('get-setting');
                        }
                    }).catch(() => {
                        this.$bvToast.toast('Ocurrió un error al actualizar el estatus.', {
                            title: 'Services',
                            variant: 'danger'
                        });
                    });
                }
            });
        },
        tableContextChanged(context) {
            this.services.sortBy = context.sortBy;
            this.services.sortDesc = context.sortDesc;

            this.saveTableState(this.$route.name, this.services);
        }
    },
    watch: {
        'services.filters': {
            handler() {
                this.$refs.servicesTable.refresh();
                this.saveTableState(this.$route.name, this.services);
            },
            deep: true
        }
    },
    computed: {
        start: function() {
            return this.services.totalRows === 0 ? 0 : (this.services.currentPage - 1) * this.services.itemsPerPage + 1;
        },
        end: function() {
            let offset = (this.services.currentPage - 1) * this.services.itemsPerPage;

            return (this.services.totalRows - offset) < this.services.itemsPerPage ? this.services.totalRows : offset + this.services.itemsPerPage;
        }
    }
};
</script>

<style scoped>

</style>